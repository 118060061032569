<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Status -->
        <template #cell(isMandotory)="data">
            <b-badge pill :variant="data.item.isMandotory?'light-success':'light-danger'">
                {{data.item.isMandotory?'ZORUNLU':'SERBEST'}}
            </b-badge>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" @click="$router.push({ name: 'branch-survey-question-page', params: { surveyId:$route.params.id,groupId: data.item.id } })">
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span class="align-middle">Sorular</span>
            </b-button>
            <b-button variant="flat-warning" @click="editSurvey(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>

    </b-table>

    <!--Open Add Survey-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addSurveyPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Grup Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Grup Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addSurveyGroup.code" />
                </b-form-group>
                <b-form-group label="Grup Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addSurveyGroup.name" />
                </b-form-group>
                <b-form-group label="Sırlama" label-for="name">
                    <b-form-input name="firstName" id="name" type="number" v-model="addSurveyGroup.viewOrder" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addSurvey">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Survey-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editSurveyPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Grup Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Anket Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editSurveyGroup.code" />
                </b-form-group>
                <b-form-group label="Anket Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editSurveyGroup.name" />
                </b-form-group>
                <b-form-group label="Sıra" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editSurveyGroup.viewOrder" />
                </b-form-group>
                <b-form-group label="İşlemler" label-for="from">
                    <b-form-checkbox v-model="editSurveyGroup.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateSurvey">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Grup Kodu' },
                { key: 'name', label: 'Grup Adı' },
                { key: "actions", label: 'İşlemler' }
            ],
            users: [],
            addSurveyPanel: false,
            editSurveyPanel: false,
            openSurveyQuestionPanel: false,
            addSurveyGroup: {
                surveyId: 0,
                code: '',
                name: '',
                viewOrder: 0
            },
            editSurveyGroup: {
                id: 0,
                code: '',
                name: '',
                viewOrder: 0,
                isActive: false
            },
            questions: []
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addSurveyPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Branch/SurveyGroup/" + this.$route.params.id);
            this.users = users.data.data;
        },
        openSurveyQuestion(data) {
            this.questions = data.questions;
            this.openSurveyQuestionPanel = true;
        },
        editSurvey(data) {
            this.editSurveyGroup = {
                id: data.id,
                code: data.code,
                name: data.name,
                viewOrder: data.viewOrder,
                isActive: data.isActive
            };
            this.editSurveyPanel = true;
        },
        addSurvey() {
            this.addSurveyGroup.surveyId = this.$route.params.id;
            this.addSurveyGroup.viewOrder = parseInt(this.addSurveyGroup.viewOrder);
            var request = this.addSurveyGroup;
            this.$http.post("Branch/AddSurveyGroup", request).then((data) => {
                    this.getData();
                    this.addSurveyPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Anket Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateSurvey() {
            var request = this.editSurveyGroup;
            request.viewOrder = parseInt(request.viewOrder);
            request.surveyId = this.$route.params.id;
            this.$http.put("Branch/UpdateSurveyGroup", request).then((data) => {
                    this.getData();
                    this.editSurveyPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Anket Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
